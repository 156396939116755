import {useCallback} from 'react'
import {shallowEqual, useSelector} from 'react-redux'
import {EPermissions} from '../models/User/Permission.model'
import {RootState} from '../store/RootReducer'

export function usePermissions() {
  const permission: EPermissions[] = useSelector<RootState>(
    ({auth}) => auth.permissions,
    shallowEqual
  ) as EPermissions[]

  const hasAccess = useCallback(
    (payload: EPermissions) => permission?.includes(payload),
    [permission]
  )

  return {hasAccess}
}
