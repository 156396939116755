import DashboardScreens from '../../modules/dashboard/routes/Screen'

export interface ScreenProp {
  PATH: string
  TITLE: string
  TITLE_ALT: string
  HEADER_NAME?: string
  PERMISSION?: string
  breadcrumbs?: (params?: Record<string, any>) => {}
}

export const DEFAULT_BREADCRUMBS = [
  {
    isActive: false,
    path: DashboardScreens.DASHBOARD.PATH,
    title: DashboardScreens.DASHBOARD.TITLE,
  },
  {isActive: false, path: '', title: '', isSeparator: true},
]

export function defaultBreadcrumbs() {
  return DEFAULT_BREADCRUMBS
}

export function generateBreadcrumb(screens: ScreenProp[], withDefault = true) {
  return [
    ...(withDefault ? DEFAULT_BREADCRUMBS : []),
    ...screens.flatMap((s) => [
      {
        isActive: false,
        path: s.PATH,
        title: s.TITLE,
      },
      {isActive: false, path: '', title: '', isSeparator: true},
    ]),
  ]
}
