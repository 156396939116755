import {EPermissions} from 'src/app/models'

export const handlePageAddAdmin = (): EPermissions => {
  const jsonData = localStorage?.getItem('persist:FSM-auth')
  let data

  try {
    data = jsonData ? JSON.parse(jsonData) : null
  } catch (error) {
    return EPermissions.ADMIN_ADD_L1
  }

  try {
    const user = data?.user ? JSON.parse(data.user) : null
    switch (user?.level?.type) {
      case 'L1':
        return EPermissions.ADMIN_ADD_L1
      case 'L2':
        return EPermissions.ADMIN_ADD_L2
      case 'L3':
        return EPermissions.ADMIN_ADD_L3
      default:
        return EPermissions.ADMIN_ADD_L1
    }
  } catch (error) {
    return EPermissions.ADMIN_ADD_L1
  }
}

export const handlePageUpdateAdmin = (): EPermissions => {
  const jsonData = localStorage?.getItem('persist:FSM-auth')
  let data

  try {
    data = jsonData ? JSON.parse(jsonData) : null
  } catch (error) {
    return EPermissions.ADMIN_UPDATE_L1
  }

  try {
    const user = data?.user ? JSON.parse(data.user) : null
    switch (user?.level?.type) {
      case 'L1':
        return EPermissions.ADMIN_UPDATE_L1
      case 'L2':
        return EPermissions.ADMIN_UPDATE_L2
      case 'L3':
        return EPermissions.ADMIN_UPDATE_L3
      default:
        return EPermissions.ADMIN_UPDATE_L1
    }
  } catch (error) {
    return EPermissions.ADMIN_UPDATE_L1
  }
}
