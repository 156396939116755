import {EPermissions, ScreenProp} from 'src/app/models'
import {handlePageAddAdmin, handlePageUpdateAdmin} from '../../util/handle-permission-page.util'

type TKeys =
  | 'USER_ADMIN'
  | 'USER_ADMIN_LIST'
  | 'USER_ADMIN_DIVISION'
  | 'USER_ADMIN_DIVISION_DETAIL'
  | 'USER_ADMIN_DIVISION_LIST'
  | 'USER_ADMIN_DIVISION_ADD'
  | 'USER_ADMIN_ADD'
  | 'USER_ADMIN_DETAIL'
  | 'USER_ADMIN_EDIT'
  | 'USER_ADMIN_DIVISION_EDIT'
  | 'USER_ADMIN_UPLOAD'

export const AdminScreen = (): Record<TKeys, ScreenProp> => ({
  // ADMIN
  USER_ADMIN: {
    PATH: '/user/admin',
    TITLE: 'Admin',
    TITLE_ALT: 'Admin',
  },
  USER_ADMIN_LIST: {
    PATH: '/user/admin/list',
    TITLE: 'Admin',
    TITLE_ALT: 'Admin',
    PERMISSION: EPermissions.ADMIN_VIEW,
  },
  USER_ADMIN_ADD: {
    PATH: '/user/admin/add',
    TITLE: 'Buat Admin',
    TITLE_ALT: 'Buat Admin',
    PERMISSION: handlePageAddAdmin(),
  },
  USER_ADMIN_DETAIL: {
    PATH: '/user/admin/detail/:id',
    TITLE: 'Detail Admin',
    TITLE_ALT: 'Detail Admin',
    PERMISSION: EPermissions.ADMIN_DETAIL,
  },
  USER_ADMIN_EDIT: {
    PATH: '/user/admin/edit/:id',
    TITLE: 'Edit Admin',
    TITLE_ALT: 'Edit Admin',
    PERMISSION: handlePageUpdateAdmin(),
  },
  USER_ADMIN_UPLOAD: {
    PATH: '/user/admin/upload',
    TITLE: 'Upload Admin',
    TITLE_ALT: 'Upload Admin',
    PERMISSION: EPermissions.ADMIN_IMPORT,
  },

  // DIVISION
  USER_ADMIN_DIVISION: {
    PATH: '/user/admin/division',
    TITLE: 'Admin Division',
    TITLE_ALT: 'Admin Division',
  },
  USER_ADMIN_DIVISION_LIST: {
    PATH: '/user/admin/division/list',
    TITLE: 'Admin Division',
    TITLE_ALT: 'Admin Division',
    PERMISSION: EPermissions.PERMISSION_VIEW,
  },
  USER_ADMIN_DIVISION_ADD: {
    PATH: '/user/admin/division/add',
    TITLE: 'Buat Divisi & Level',
    TITLE_ALT: 'Buat Divisi',
    PERMISSION: EPermissions.PERMISSION_FORM,
  },
  USER_ADMIN_DIVISION_DETAIL: {
    PATH: '/user/admin/division/detail/:id',
    TITLE: 'Detail Divisi & Level',
    TITLE_ALT: 'Admin Division Detail',
    PERMISSION: EPermissions.PERMISSION_DETAIL,
  },
  USER_ADMIN_DIVISION_EDIT: {
    PATH: '/user/admin/division/edit/:id',
    TITLE: 'Edit Divisi & Level',
    TITLE_ALT: 'Edit Divisi',
    PERMISSION: EPermissions.PERMISSION_FORM,
  },
})
